import React from 'react';
import Container from "@mui/material/Container";
import classes from './TermsAndConditionsPage.module.scss';

const TermsAndConditionsPage = () => {
  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{ display: 'flex', flexDirection: 'column', my: 16}}
    >
      <div className={'page-title'}>
        Terms and Conditions (Terms of Service)
      </div>
      <div className={classes.contents}>

        <h2>Effective Date: Jan 30, 2024</h2>
        <p>By opting in to receive SMS messages from [Innovative Dental Solutions / Haneul Shin DDS] (“we”, “us”, “our”), you agree to the following terms:</p>

        <h2>1. SMS Messaging Service</h2>
        <p>By providing your phone number, you consent to receive SMS messages, including updates, promotions, and other relevant content.</p>

        <h2>2. Message Frequency</h2>
        <p>You will receive up to 2 messages per appointments.</p>

        <h2>3. Message and Data Rates</h2>
        <p>Message and data rates may apply based on your mobile carrier’s terms.</p>

        <h2>4. Privacy Policy</h2>
        <p>Your information will be handled in accordance with our Privacy Policy. which can be viewed at this link.</p>

        <h2>5. Opt-Out Instructions</h2>
        <p>You can opt out at any time by replying “STOP” to any SMS message. You may also contact us directly at (925)284-2203. must have reply HELP instructions for help.</p>

        <h2>6. Liability</h2>
        <p>We are not responsible for any charges, errors, or delays in SMS delivery caused by your carrier or third-party service providers.</p>

        <p>By opting in, you confirm that you are the owner or authorized user of there phone number provided and that you are at least 18 years old.</p>

      </div>

    </Container>
  )
};

export default TermsAndConditionsPage;
