import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classes from './HighlightedServiceItem.module.scss';
import {Button, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";

const HighlightedServiceItem = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [hovered, setHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div className={classes.highlightItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
      <div className={classes.iconTreeWrapper}>
        <div className={classes.iconWrapper}
             style={{borderColor: hovered ? theme.vars.palette.primary.main : theme.vars.palette.text.secondary,
               color: hovered ? theme.vars.palette.primary.main : theme.vars.palette.text.secondary
             }} >
          <FontAwesomeIcon className={classes.itemIcon} style={{color: hovered ? theme.vars.palette.primary.main : theme.vars.palette.text.primary}}
                           icon={props.icon} />
        </div>
        <div className={classes.iconStem} style={{borderColor: hovered ? theme.vars.palette.primary.main : theme.vars.palette.text.secondary}}></div>
      </div>
      <div className={classes.highlightContents}>
        <div className={classes.highlightTitle} style={{color: hovered ? theme.vars.palette.primary.main : theme.vars.palette.text.secondary}}>
          {(props.title || '').toUpperCase()}
        </div>
        <div className={classes.highlightDescription}>
          {props.description}
        </div>
        <Button className={classes.highlightLink}
                size={'medium'} variant='contained' color='primary'
                onClick={() => navigate(props.link)}>
          More Information
        </Button>
      </div>
    </div>
  );
}

export default HighlightedServiceItem;
