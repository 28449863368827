import React from 'react';
import classes from './MainPage.module.scss';
import ImageRotator from "../../components/main/ImageRotator";
import HighlightedService from "../../components/main/HighlightedService";
import {Container} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {DefaultSEOInfo} from "../../contents/SiteInformations";

const MainPage = () => {
  return (
    <div className={classes.pageWrapper}>
      <Helmet>
        <title>{DefaultSEOInfo.title}</title>
        <meta name="description" content={DefaultSEOInfo.description} />
        <meta property="og:title" content={DefaultSEOInfo.title} />
        <meta property="og:description" content={DefaultSEOInfo.description} />
        <meta property="og:url" content='https://www.innovativedental.org' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={DefaultSEOInfo.title} />
        <meta name="twitter:description" content={DefaultSEOInfo.description} />
      </Helmet>
      <Container
        maxWidth="lg"
        component="main"
        sx={{ display: 'flex', flexDirection: 'column', my: 16, gap: 4, marginBottom: 0}}
      >
        <ImageRotator />
      </Container>
      <HighlightedService />
      <Container maxWidth="lg"
                 component="main"
                 sx={{ display: 'flex', flexDirection: 'column', my: 16, marginTop: '20px', marginBottom: '20px'}}>
        <h1>Meet Innovative Dental Solutions</h1>
        <p>We are a biomimetic dental practice. What does that mean? Less painful. Less invasive. Less expensive over time. We are not your average drill and fill dental practice. We rebuild teeth and make them stronger, and in most cases eliminate the need for root canals and crowns. The use of Ozone plays a key role in this process. Before you schedule your next dental appointment, call us.</p>
        <p>At Innovative Dental Solutions we offer you a modern approach to civil war dentistry. With the use of modern science and our focus on the natural make up of your teeth, we are able to provide minimally invasive techniques that save your teeth and make them stronger. After all, you shouldn’t have to cringe when thinking about visiting the dentist office.</p>

        <h1>Kid Tested, Mother Approved Dentistry</h1>
        <p>It goes without saying that parents fear that their child’s dental visit might be like their own first visit, painful. With minimally invasive Biomimetic Dentistry we are able to provide an experience unparalleled to traditional dentistry. This means without pain, without general anesthesia and without shots; yes that means without injections! We insist mom or dad be in the room with us during the procedure. Our dentist, assistants and hygienist work in tandem to make the experience fast and enjoyable. We use buzzwords like powder spray, bumpy machine, puffs of magic air. It’s almost like magic! Come see the difference. (The tooth fairy has been known to make appearances during your child’s visit, but don’t tell them!)</p>

        <h1>Our Promise</h1>
        <p>Our method of treatment gets straight to the point. We are here to save teeth, period. Thanks to science, we’re able to offer an alternative to drilling cavities and placing ‘civil war’ crowns. We want to avoid treatment that kills teeth. We also use Ozone Therapy to desensitize cold sensitive teeth and to kill viruses that can cause canker sores, stopping pain almost immediately. Ozone also fights off bacteria that cause your teeth to decay. Say hello to the future of dentistry and goodbye to dreading your next visit.</p>
      </Container>
    </div>
  );
}
export default MainPage;
