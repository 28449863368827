import React from 'react';
import {Container, Divider, ImageList, ImageListItem, useTheme} from "@mui/material";
import classes from './ServicesPage.module.scss';
import {useNavigate} from "react-router-dom";
import {OfficePhotos, ServiceItems} from "../../contents/SiteInformations";
import {Helmet} from "react-helmet-async";
const ServicesPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const SEO = {
    title: 'Innovative Dental Solutions - Services',
    description: 'Experience the future of dentistry with Minimally Invasive Biomimetic Dentistry. We rebuild teeth layer by layer using advanced adhesive bonding, micro-air abrasion, and ozone therapy—preserving natural tooth structure while preventing fractures and decay. No high-speed drills, no unnecessary damage—just stronger, healthier teeth. Explore our services: Biomimetic Dentistry, Ozone Therapy, Crowns, Pediatric Dentistry, Dental Implants, and more. Choose a healthier, biological approach to dental care today!'
  };

  const srcset = (image: string, size: number, rows = 1, cols = 1) => {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{ display: 'flex', flexDirection: 'column', my: 16}}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="description" content={SEO.description} />
        <meta property="og:title" content={SEO.title} />
        <meta property="og:description" content={SEO.description} />
        <meta property="og:url" content='https://www.innovativedental.org/about-us' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={SEO.title} />
        <meta name="twitter:description" content={SEO.description} />
      </Helmet>
      <div className={'page-title'}>
        A difference in dentistry
      </div>
      <h1>We Don't Fill Holes, We Rebuild Teeth, biologically.</h1>
      <p>
        As a Minimally Invasive Biomimetic Dentist our main goal is to restore decayed and damaged teeth to near original strength while preserving as much natural tooth as possible.  We do this using adhesive bonding, layer by layer, rebuilding your tooth.
      </p>
      <h2>How do we do this?</h2>
      <p>
        We use micro-air abrasion to remove decay, never the high speed drill, that’s reserved for removing old ‘fillings’. Liquid stains and laser fluorescence tell us what part of the tooth is decayed and needs to be removed. We leave what’s healthy.  The tooth is then treated with Ozone (see Ozone procedure) to eliminate any bacteria that may still be present. State of the art bonding materials are then used to seal and rebuild the tooth one layer at a time. Using the correct materials we are able to maintain the structural integrity of the tooth, seal it from bacterial invasion, and greatly reduce the amount of trauma inflicted.
      </p>
      <h2>Why do we do it this way?</h2>
      <p>
        As a dentist, my promise is to do what is best for the tooth by saving teeth and keeping them alive through minimal treatment. Micro-air abrasion allows us to remove decay without shots and without drills. Ozone is used on the tooth to help prevent recurrent decay by killing even the slightest amount of bacteria. Bonding to a tooth helps to maintain its natural structure and flexure. Traditional silver-mercury fillings need a specific shape or undercut in order to hold them in place, resulting in cracks, more tooth loss and no bond or seal to the tooth. By mimicking your natural tooth’s abilities we can greatly reduce the development of devastating fractures.
      </p>
      <h1 style={{textAlign: 'center'}} >WE PROVIDE OPTIONS; YOU CHOOSE</h1>
      <div className={classes.serviceItemWrapper}>
        {
          ServiceItems.map((service, index) => (
            <li key={index} className={classes.serviceItem} style={{color: theme.palette.primary.main}}><span className={classes.itemText} onClick={() => navigate(service.link)} >{service.title}</span></li>
          ))
        }
      </div>
      <Divider style={{marginTop: '40px', marginBottom: '0px'}} />
      <h1>Our Office</h1>
      <ImageList sx={{ display: {xs: 'none', md: 'grid'}}} variant={"quilted"} cols={4} rowHeight={200}>
        { OfficePhotos.map((item, idx) => (
          <ImageListItem key={idx} cols={item.cols || 1} rows={item.rows || 1}>
            <img loading='lazy' key={idx} alt={''}
                 {...srcset(item.img, 121, item.rows, item.cols)} />
          </ImageListItem>
          ))
        }
      </ImageList>
      <ImageList sx={{display: {xs: 'grid', md: 'none'}}} cols={1} >
        { OfficePhotos.map((item, idx) => (
          <ImageListItem key={idx} cols={1} rows={1}>
            <img loading='lazy' key={idx} alt={''}
                 {...srcset(item.img, 121, 1, 1)} />
          </ImageListItem>
        ))
        }
      </ImageList>
    </Container>
  );
}

export default ServicesPage;
