import React from 'react';
import Container from "@mui/material/Container";
import classes from '../ServicesPage.module.scss';
import MainImage from '../../../assets/services/cleaning.jpg';
import {Helmet} from "react-helmet-async";
const DentalCleaningHygienePage = () => {
  const SEO = {
    title: 'Innovative Dental Solutions - Dental Cleaning & Hygiene',
    description: 'Preventive dental care starts with education and proactive hygiene. Our comprehensive dental exams include X-ray evaluations, oral cancer screenings, gum disease assessments, and decay detection to keep your smile healthy and confident. Avoid costly dental problems with early prevention and expert care. Schedule your exam today for a brighter, healthier future!'
  };
  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{display: 'flex', flexDirection: 'column', my: 16}}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="description" content={SEO.description} />
        <meta property="og:title" content={SEO.title} />
        <meta property="og:description" content={SEO.description} />
        <meta property="og:url" content='https://www.innovativedental.org/about-us' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={SEO.title} />
        <meta name="twitter:description" content={SEO.description} />
      </Helmet>

      <div className={'page-title'}>
        Dental Cleaning & Hygiene
      </div>
      <div className={classes.contents}>
        <img className={classes.contentsImage} src={MainImage} alt={''}/>
        <p>Hygiene is not only about having someone clean your teeth.  First and foremost, effective hygiene is about your own education and prevention.  Prevention helps avoid serious and costly dental problems and is the key to having a healthy, confident, beautiful smile. Like the beautiful smiles you see in the photo above – it all starts in the mouth.</p>
        <h1>Dental Exam</h1>
        <p>A comprehensive dental exam will be performed by Dr. Shin and includes the following:
          <li>Examination of diagnostic x-rays (radiographs).</li>
          <li>Oral cancer screening</li>
          <li>Gum disease evaluation</li>
          <li>Examination for tooth decay and cracked teeth</li>
        </p>
      </div>
    </Container>
  )
};

export default DentalCleaningHygienePage;
