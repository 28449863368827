import _LogoImage from '../assets/full_color_logo.png';

import _MainImage1 from '../assets/main/main_1.jpg';
import _MainImage2 from '../assets/main/main_2.jpeg';
import _MainImage3 from '../assets/main/main_3.jpeg';
import _MainImage4 from '../assets/main/main_4.jpg';
import _MainImage5 from '../assets/main/main_5.jpg';
import _MainImage6 from '../assets/main/main_6.jpg';
import _MainImage7 from '../assets/main/main_7.jpg';
import _MainImage8 from '../assets/main/main_8.jpg';
import _MainImage9 from '../assets/main/main_9.jpg';
import _MainImage10 from '../assets/main/main_10.jpg';

import O1 from '../assets/office/O1.jpg';
import O2 from '../assets/office/O2.jpg';
import O3 from '../assets/office/O3.jpg';
import O4 from '../assets/office/O4.jpg';
import O5 from '../assets/office/O5.jpg';
import O6 from '../assets/office/O6.jpg';
import O7 from '../assets/office/O7.jpg';
import O8 from '../assets/office/O8.jpg';

import {faEnvira} from "@fortawesome/free-brands-svg-icons/faEnvira";
import {faFlask} from "@fortawesome/free-solid-svg-icons/faFlask";
import {faTooth} from "@fortawesome/free-solid-svg-icons/faTooth";

export const DefaultSEOInfo = {
  title: 'Innovative Dental Solution / Haneul Shin DDS',
  description: 'Biomimetic Dentistry in EastBay(Lafayette/Orinda/Moraga)'
};


export const LogoImage = _LogoImage;

export const MainMenuInfo: any = {
  'about-us': {
    title: 'About Us',
    url: '/about-us',
  },
  'services': {
    title: 'Services',
    url: '/services',
  },
  'contact-us': {
    title: 'Contact Us',
    url: '/contactus',
  },
};

export const OfficeName = 'Innovative Dental Solutions';

export const PhoneNumber = {
  'display': '(925) 284 - 2203',
  'href': 'tel:+19252842203'
};

export const Location = {
  'display': '3509 School St, Lafayette, CA 94549',
  'href': 'https://maps.app.goo.gl/xaXHcXP3WasLrqmW7',
  'googleMap': {
    'mapId': 'f7d2bf8da455a0cc',
    'apiKey': 'AIzaSyA4GsS5ClHkF_3ye2LFVOx-eYMk4bSc-lY',
    lat: 37.88780,
    lng: -122.11716
  }
};

export const Email = {
  'display': 'info@innovativedental.org',
  'href': 'mailto:info@innovativedental.org',
};

export const OfficeHours = [
  'Monday - Thursday: 08:00 AM - 05:00 PM',
  'Closed: Friday - Sunday'
];

export const MainImageRotatorList = [
  {
    image: _MainImage2,
    link: null,
  },
  {
    image: _MainImage1,
    link: null,
  },
  {
    image: _MainImage3,
    link: null,
  },
  {
    image: _MainImage4,
    link: null,
  },
  // {
  //   image: _MainImage5,
  //   link: null,
  // },
  {
    image: _MainImage7,
    link: null,
  },
  // {
  //   image: _MainImage8,
  //   link: null,
  // },
  {
    image: _MainImage6,
    link: null,
  },
  {
    image: _MainImage9,
    link: null,
  },
  {
    image: _MainImage10,
    link: null,
  }
];

export const OfficePhotos = [
  {
    img: O1,
    cols: 4,
    rows: 2,
  },
  {
    img: O2,
    cols: 2,
    rows: 1,
  },
  {
    img: O3,
  },
  {
    img: O4,
  },
  {
    img: O5,
    cols: 2,
    rows: 2,
  },
  {
    img: O6,
    cols: 2,
    rows: 2,
  },
  {
    img: O7,
    cols: 2,
    rows: 2,
  },
  {
    img: O8,
    cols: 2,
    rows: 2,
  },
];


export const HighlightedServices = [
  {
    title: 'Biomimetic Dentistry',
    description: '',
    icon: faEnvira,
    link: '/biomimetic-dentistry',
  },
  {
    title: 'Ozone Therapy',
    description: '',
    icon: faFlask,
    link: '/ozone-therapy',
  },
  {
    title: 'Dental Implants',
    description: '',
    icon: faTooth,
    link: '/dental-implants',
  }
];

export const ServiceItems = [
  {
    title: 'Biomimetic Dentistry',
    link: '/biomimetic-dentistry',
  },
  {
    title: 'Ozone Therapy',
    link : '/ozone-therapy'
  },
  {
    title: 'Crowns',
    link : '/crowns'
  },
  {
    title: 'Pediatric Dentistry',
    link : '/pediatric-dentistry'
  },
  {
    title: 'Dental X-Rays',
    link : '/dental-x-rays'
  },
  {
    title: 'Dental Cleaning & Hygiene',
    link : '/dental-cleaning-hygiene'
  },
  {
    title: 'Dental Implants',
    link : '/dental-implants'
  },
  {
    title: 'Biological Root Canal Therapy',
    link : '/biological-root-canal'
  },
  {
    title: 'Home Hygiene',
    link : '/home-hygiene'
  }
];
