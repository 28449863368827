import React, {useState} from 'react';
import classes from './HighlightedService.module.scss';
import {Container, Grid2, useTheme} from "@mui/material";
import HighlightedServiceItem from "./HighlightedServiceItem";

import {HighlightedServices} from "../../contents/SiteInformations";

const HighlightedService = () => {
  const theme = useTheme();
  const [sizePerItem, ] = useState(Math.floor(12 / HighlightedServices.length));

  //display: { xs: 'flex', md: 'none' }
  return (
    <div className={classes.highlightsWrapper} style={{borderColor: theme.vars.palette.text.secondary}}>
      <Container
        maxWidth="lg"
        component="main"
        sx={{ display: 'flex', flexDirection: 'column', my: 16, gap: 4, marginBottom: 0, marginTop: '24px'}}
      >
        <div className={classes.highlightsArea}>
        <Grid2 container direction={{'xs': 'column', 'md': 'row'}}>
          {
            HighlightedServices.map((service, index) => (
              <Grid2 size={{ 'xs': 12, 'md': sizePerItem}} key={index}>
                <HighlightedServiceItem icon={service.icon}
                                        title={service.title}
                                        description={service.description}
                                        link={service.link} />
              </Grid2>
            ))
          }
        </Grid2>
        </div>
      </Container>
    </div>
  )
}

export default HighlightedService;
