import React from 'react';
import Container from "@mui/material/Container";
import classes from '../ServicesPage.module.scss';
import MainImage from '../../../assets/services/pediatric.jpg';
import {ImageList, ImageListItem, useTheme} from "@mui/material";

import Img1 from '../../../assets/services/image-7.jpg';
import Img2 from '../../../assets/services/image-8.jpg';
import Img3 from '../../../assets/services/image-9.jpg';
import Img4 from '../../../assets/services/image-10.jpg';
import Img5 from '../../../assets/services/image-11.jpg';
import Img6 from '../../../assets/services/image-12.jpg';
import {Helmet} from "react-helmet-async";

const PediatricDentistryPage = () => {
  const theme = useTheme();
  const SEO = {
    title: 'Innovative Dental Solutions - Pediatric Dentistry',
    description: 'Pain-free, no-shot dentistry for kids! At Innovative Dental Solutions, we use minimally invasive techniques like air abrasion and ozone therapy to prevent decay—eliminating the need for drills, shots, stainless steel crowns, and root canals. Our kid-friendly approach ensures a fun, stress-free experience with parents by their side. Give your child the best start to lifelong dental health with modern, gentle care. Schedule a consultation today!',
  }
  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{display: 'flex', flexDirection: 'column', my: 16}}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="description" content={SEO.description} />
        <meta property="og:title" content={SEO.title} />
        <meta property="og:description" content={SEO.description} />
        <meta property="og:url" content='https://www.innovativedental.org/about-us' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={SEO.title} />
        <meta name="twitter:description" content={SEO.description} />
      </Helmet>
      <div className={'page-title'}>
        Pediatric Dentistry
      </div>
      <div className={classes.contents}>
        <img className={classes.contentsImage} src={MainImage}  alt={''}/>
        <p>We are proud to say Innovative Dental Solutions is kid tested, mother approved. That’s because the approach we take with treating your child is pain-free and requires no shots or anesthesia. Our dental team is highly trained in the care of your child to ensure that we create an enjoyable experience so they aren’t afraid to go to the dentist. A visit from the tooth fairy at the end of the exam never hurts either.</p>
        <p>Our vocabulary is specifically designed to use fun and exciting terms while we treat your children. You’ll hear us say a lot of funny words like squirt gun and smelly stuff and pockets full of air. That’s not just because we are kids at heart, but because it’s our standard of care when treating children.</p>
        <p>Did we mention, moms and dads are part of our team! We insist that you are in the room during the office visit. It’s hugely reassuring for your child to have you there, holding a hand or a foot.  It can get a little crowded but we make it work!</p>
        <h1>Why minimally invasive dentistry is the best for your child.</h1>
        <p>Because the best dentistry is no dentistry. It all starts with the child.</p>
        <p>There is a better way through minimally invasive preventative treatment. Offering this form of treatment means no shots, no general anesthesia, never having stainless steel crowns and rarely root canals. Yes, we know it sounds impossible, but it’s true.</p>
        <h1>Sealants and decay prevention.</h1>
        <p>There is a better way. At Innovative Dental Solutions we use air-abrasion or a dental sandblaster, not a drill, to clean the chewing surface groove. This technique removes the mush without damaging the enamel. Now ozone therapy to kill the bugs that might be hiding out microscopically followed by a mineral sealer that thinks its enamel, to bond covalently to the enamel. These sealants can last decades. Most importantly they allow saliva chemistry to leak through the groove and harden the enamel, but bugs still cannot get in. (By the way, no shots and no drills.)</p>
        <h1>Why don't all dentists offer this?</h1>
        <p>Most dentist are happy practicing civil war dentistry. Doing root canals and stainless steel crowns generates a lot more income. In my office, no tooth deserves a crown; whether it’s a baby tooth or a permanent tooth. This really is better dentistry.</p>
        <h1>Have you been told your child needs stainless steel crowns or root canals (pulpotomies)?</h1>
        <p>STOP… don’t do it. There is a better treatment solution. The goal in treating your child is decay management until the baby tooth is naturally lost.  So, less decay needs to be removed. Modern materials and techniques allow us to stop the decay and harden damaged areas; without shots, without general anesthesia and without the loud high-speed drills.</p>
        <p>What’s actually being recommended by traditional dentistry is treatment based on gross amputation, the surgical standard for the American Civil War. We refer to this as ‘civil-war’ dentistry. These treatments can be traumatic to a child, let alone an adult.</p>
        <p>Before you book your next stainless steel crown appointment or procedure, call Innovative Dental Solutions for a second opinion.</p>
        <div className={classes.divider} style={{borderColor: theme.palette.text.secondary}}></div>
        <h1>We Love Kids</h1>
        <p>Calie & Mom visit:</p>
        <ImageList cols={3}>
          <ImageListItem>
            <img src={Img1} alt={''} loading="lazy" />
          </ImageListItem>
          <ImageListItem>
            <img src={Img2} alt={''} loading="lazy" />
          </ImageListItem>
          <ImageListItem>
            <img src={Img3} alt={''} loading="lazy" />
          </ImageListItem>
          <ImageListItem>
            <img src={Img4} alt={''} loading="lazy" />
          </ImageListItem>
          <ImageListItem>
            <img src={Img5} alt={''} loading="lazy" />
          </ImageListItem>
          <ImageListItem>
            <img src={Img6} alt={''} loading="lazy" />
          </ImageListItem>
        </ImageList>
      </div>
    </Container>
  )
};

export default PediatricDentistryPage;
