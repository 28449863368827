import React from 'react';
import {Container, Grid2, useTheme} from "@mui/material";
import classes from './ContactUsPage.module.scss';
import {AdvancedMarker, APIProvider, Map, Pin} from "@vis.gl/react-google-maps";
import {useNavigate} from "react-router-dom";
import {Email, Location, OfficeHours, OfficeName, PhoneNumber} from "../../contents/SiteInformations";
import {Helmet} from "react-helmet-async";
import OfficeImage1 from '../../assets/contact-us/main_1.jpeg';
import OfficeImage2 from '../../assets/contact-us/o_2.jpg';

const ContactUsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClickTC = () => {
    navigate('/terms');
  };
  const handleClickPP = () => {
    navigate('/privacy-policy');
  };

  const SEO = {
    title : 'Eastbay[Lafayette/Orinda/Moraga] Dental Clinic',
    description: 'Biomimetic Dentistry. Dental Implants.'
  };

  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{ display: 'flex', flexDirection: 'column', my: 16}}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="description" content={SEO.description} />
        <meta property="og:title" content={SEO.title} />
        <meta property="og:description" content={SEO.description} />
        <meta property="og:url" content='https://www.innovativedental.org/contactus' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={SEO.title} />
        <meta name="twitter:description" content={SEO.description} />
      </Helmet>
      <div className={'page-title'}>
        Contact Us
      </div>
      <div className={classes.scheduleTitle} style={{color: theme.vars.palette.primary.main}}>
        To Schedule An Appointment please call:
      </div>
      <div className={classes.scheduleTitle} style={{color: theme.vars.palette.primary.main}}>
        {PhoneNumber.display}
      </div>
      <div className={classes.divider} style={{borderColor: theme.vars.palette.text.secondary}}>
      </div>
      <div className={classes.subTitle} style={{color: theme.vars.palette.primary.main}}>
        {OfficeName}
      </div>
      <Grid2 container className={classes.contactInfoWrapper}
             direction={{xs: 'column', md: 'row'}}>
        <Grid2 size={{xs: 12, md: 6}} style={{padding: '5px'}}>
          <img className={classes.officeImage} src={OfficeImage1} alt={''}/>
        </Grid2>
        <Grid2 size={{xs: 12, md: 6}} style={{padding: '5px'}}>
          <img className={classes.officeImage} src={OfficeImage2} alt={''}/>
        </Grid2>
      </Grid2>
      <div className={classes.contactInfo}>
        {Location.display}
      </div>
      <div className={classes.contactInfo}>
        {PhoneNumber.display}
      </div>
      <div className={classes.contactInfo}>
        {Email.display}
      </div>
      <APIProvider apiKey={Location.googleMap.apiKey}>
        <div className={classes.mapWrapper}>
          <Map
            mapId={Location.googleMap.mapId}
            defaultZoom={14}
            defaultCenter={ { lat: Location.googleMap.lat, lng: Location.googleMap.lng } }>
            <AdvancedMarker position={{ lat: Location.googleMap.lat, lng: Location.googleMap.lng }}>
              <Pin background={'rgb(224,45,46)'} glyphColor={'#000'} borderColor={'#000'} />
            </AdvancedMarker>
          </Map>
        </div>
      </APIProvider>
      <div className={classes.subTitle} style={{color: theme.vars.palette.primary.main}}>
        Our Hours
      </div>
      {
        OfficeHours.map((hour, index) => (
          <div key={index} className={classes.contactInfo}>
            {hour}
          </div>
        ))
      }
      <div className={classes.divider} style={{borderColor: theme.vars.palette.text.secondary}}></div>
      <div className={classes.subTitle2} style={{color: theme.vars.palette.primary.main}}>
        Terms and Conditions & Privacy Policy
      </div>
      <li className={classes.link}>
        <span style={{cursor: 'pointer'}} onClick={handleClickTC}>Terms and Conditions (Terms of Service)</span>
      </li>
      <li className={classes.link}>
        <span style={{cursor: 'pointer'}} onClick={handleClickPP} >Privacy Policy</span>
      </li>
    </Container>
  );
}

export default ContactUsPage;
