import React from 'react';
import Container from "@mui/material/Container";
import classes from '../ServicesPage.module.scss';
import MainImage from '../../../assets/services/xrays.jpg';
import {Helmet} from "react-helmet-async";
const DentalXRaysPage = () => {
  const SEO = {
    title: 'Innovative Dental Solutions - Dental X-Rays',
    description: 'Early detection saves teeth! Dental X-rays are essential for uncovering hidden issues like decay, abscesses, bone loss, tumors, and developmental abnormalities. Without X-rays, serious problems may go undetected, leading to costly and painful treatments later. Stay proactive with preventative diagnostics—schedule your dental exam today for a healthier, long-lasting smile!'
  }
  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{display: 'flex', flexDirection: 'column', my: 16}}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="description" content={SEO.description} />
        <meta property="og:title" content={SEO.title} />
        <meta property="og:description" content={SEO.description} />
        <meta property="og:url" content='https://www.innovativedental.org/about-us' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={SEO.title} />
        <meta name="twitter:description" content={SEO.description} />
      </Helmet>
      <div className={'page-title'}>
        Dental X-Rays
      </div>
      <div className={classes.contents}>
        <img className={classes.contentsImage} src={MainImage} alt={''}/>
        <p>Dental radiographs (x-rays) are essential, preventative, diagnostic tools that provide valuable information not visible during a regular dental exam.  Without x-rays, problem areas may go undetected.</p>
        <h1>Dental x-rays may reveal:</h1>
        <p>
          <li>Abscesses or cysts:  frequently seen on teeth with big fillings or ‘crowns’</li>
          <li>Bone loss</li>
          <li>Cancerous and noncancerous tumors</li>
          <li>Decay between the teeth</li>
          <li>Developmental abnormalities: missing, impacted or malformed teeth</li>
          <li>Poor tooth and root positions, which compromises bone health between teeth</li>
          <li>Problems inside a tooth or below the gum line</li>
        </p>
        <p>Detecting and treating dental problems at an early stage can save you time, money, unnecessary discomfort, and your teeth!  Most dental pathology starts out painless.</p>
      </div>
    </Container>
  )
};

export default DentalXRaysPage;
