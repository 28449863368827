import React from 'react';
import Container from "@mui/material/Container";
import classes from '../ServicesPage.module.scss';
import MainImage from '../../../assets/services/crowns.jpg';
import {Helmet} from "react-helmet-async";
const CrowPage = () => {
  const SEO = {
    title: 'Innovative Dental Solutions - Crowns',
    description: 'Say goodbye to outdated ‘civil war’ crowns! Biomimetic Dentistry restores teeth without grinding them down to stumps. By removing only decay and cracks, we preserve healthy enamel and rebuild teeth using advanced bonding techniques—sealing against bacteria and maintaining natural strength. No unnecessary drilling, no irreversible nerve damage, just truly minimally invasive dentistry. Keep your teeth intact and healthy with modern, science-backed care. Discover a better alternative to traditional crowns today!'
  }
  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{display: 'flex', flexDirection: 'column', my: 16}}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="description" content={SEO.description} />
        <meta property="og:title" content={SEO.title} />
        <meta property="og:description" content={SEO.description} />
        <meta property="og:url" content='https://www.innovativedental.org/about-us' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={SEO.title} />
        <meta name="twitter:description" content={SEO.description} />
      </Helmet>

      <div className={'page-title'}>
        Crowns
      </div>
      <div className={classes.contents}>
        <img className={classes.contentsImage} src={MainImage} alt={''}/>
        <h1>What is a crown?</h1>
        <p>A crown is a restoration that traditionally covers or “caps” the entire tooth. (It was modern in the civil war.) Basically, a crown is a tooth shaped hat that fits over and around your tooth. The models featured in the photo above don’t have any crowns and we don’t believe you should either.</p>
        <h1>Traditional Dentistry Crown vs Biomimetic Dentistry Crown</h1>
        <p>In traditional dentistry the dentist will take a drill, run it around the outside of the tooth and grind the tooth down to the gum line to make a stump. (How is this healthy?) So, healthy tooth structure is amputated ONLY to make a ‘civil-war’ crown possible, NOT because it is good for your tooth. Most often the enamel being aggressively drilled away is healthy and we think should remain.</p>
        <p>With Biomimetic Dentistry we only remove decay, cracks and fractures saving as much of your healthy tooth as possible. Then, we bond the tooth to seal against new bacterial infection and restore strength to the tooth by adding back what is missing by using advanced bonding techniques. Bottom line, you keep your tooth, and avoid it being ground down to nothing more than a stump.</p>
        <h1>Why don't we think your tooth deserves a crown? (Other than the fact that we don't think you need one.)</h1>
        <p>
          <li>Capping a tooth is an invasive procedure</li>
          <li>Capping a tooth requires complete removal of the strong, healthy enamel (Why amputate the leg if the problem is only in the foot?)</li>
          <li>Crowns can lead to irreversible damage of the nerve, requiring root canal therapy</li>
          <li>Dental adhesives are so advanced crowns are no longer our only choice for rebuilding teeth</li>
        </p>
        <h1>How we do it differently!</h1>
        <p>
          <li>Decay is removed (obviously)</li>
          <li>Cracks and fractures are removed, too. Traditional dentistry never mentions this</li>
          <li>All remaining healthy tooth structure is left</li>
          <li>The tooth is bonded to seal against bacterial invasion</li>
          <li>Restore original strength</li>
          <li>Happy Chewing!</li>
        </p>
        <h1>The benefits of "No Civil War Crown" dentistry:</h1>
        <p>
          <li>It’s TRULY ‘Minimally Invasive’ dentistry</li>
          <li>You keep more of your natural tooth</li>
          <li>As a Biomimetic Dentist our goal is to mimic the biological structure of your natural tooth</li>
          <li>Bonding adhesives have become so advanced there is no need for “Civil-War” dentistry</li>
        </p>
      </div>
    </Container>
  )
};

export default CrowPage;
