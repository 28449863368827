import React from 'react';
import {Navigate, RouteObject} from "react-router-dom";
import MainPage from "../pages/main/MainPage";
import AboutUsPage from "../pages/about-us/AboutUsPage";
import ServicesPage from "../pages/services/ServicesPage";
import ContactUsPage from "../pages/contact-us/ContactUsPage";
import TermsAndConditionsPage from "../pages/privacy/TermsAndConditionsPage";
import PrivacyPolicyPage from "../pages/privacy/PrivacyPolicyPage";
import BiomimeticDentistryPage from "../pages/services/sub/BiomimeticDentistryPage";
import OzoneTherapyPage from "../pages/services/sub/OzoneTherapyPage";
import DentalImplantPage from "../pages/services/sub/DentalImplantPage";
import CrownPage from "../pages/services/sub/CrownPage";
import PediatricDentistryPage from "../pages/services/sub/PediatricDentistryPage";
import DentalXRaysPage from "../pages/services/sub/DentalXRaysPage";
import DentalCleaningHygienePage from "../pages/services/sub/DentalCleaningHygienePage";
import BiologicalRootCanalPage from "../pages/services/sub/BiologicalRootCanalPage";
import HomeHygienePage from "../pages/services/sub/HomeHygienePage";

const routes = (): RouteObject[] => [
  {
    path: '/',
    element: <MainPage />,
  },
  {
    path: '/about-us',
    element: <AboutUsPage />
  },
  {
    path: '/services',
    element: <ServicesPage />,
  },
  {
    path: '/services/biomimetic-dentistry',
    element: <BiomimeticDentistryPage />
  },
  {
    path: '/services/ozone-therapy',
    element: <OzoneTherapyPage />
  },
  {
    path: '/services/dental-implants',
    element: <DentalImplantPage />
  },
  {
    path: '/services/crowns',
    element: <CrownPage />
  },
  {
    path: '/services/pediatric-dentistry',
    element: <PediatricDentistryPage />
  },
  {
    path: '/services/dental-x-rays',
    element: <DentalXRaysPage />
  },
  {
    path: '/services/dental-cleaning-hygiene',
    element: <DentalCleaningHygienePage />
  },
  {
    path: '/services/biological-root-canal',
    element: <BiologicalRootCanalPage />
  },
  {
    path: '/services/home-hygiene',
    element: <HomeHygienePage />
  },
  {
    path: '/biomimetic-dentistry',
    element: <BiomimeticDentistryPage />
  },
  {
    path: '/ozone-therapy',
    element: <OzoneTherapyPage />
  },
  {
    path: '/dental-implants',
    element: <DentalImplantPage />
  },
  {
    path: '/crowns',
    element: <CrownPage />
  },
  {
    path: '/pediatric-dentistry',
    element: <PediatricDentistryPage />
  },
  {
    path: '/dental-x-rays',
    element: <DentalXRaysPage />
  },
  {
    path: '/dental-cleaning-hygiene',
    element: <DentalCleaningHygienePage />
  },
  {
    path: '/biological-root-canal',
    element: <BiologicalRootCanalPage />
  },
  {
    path: '/home-hygiene',
    element: <HomeHygienePage />
  },
  // Terms and Conditions
  {
    path: '/terms',
    element: <TermsAndConditionsPage />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicyPage />
  },
  {
    path: '/contactus',
    element: <ContactUsPage />
  },
  {
    path: '/contact-us',
    element: <ContactUsPage />
  },
  {
    path: '*',
    element: <Navigate to={'/'} replace />,
  }
];

export default routes;
