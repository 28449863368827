import React from 'react';
import Container from "@mui/material/Container";
import classes from '../ServicesPage.module.scss';
import {Helmet} from "react-helmet-async";

const BiomimeticDentistryPage = () => {
  const SEO = {
    title: 'Innovative Dental Solutions - Biomimetic Dentistry',
    description: 'Biomimetic Dentistry preserves and rebuilds your natural teeth using advanced adhesive materials—no unnecessary cutting, no outdated ‘civil war’ crowns. Like Luke Skywalker\'s bionic hand, we restore strength, flexibility, and function layer by layer, ensuring longevity and minimal trauma. Say goodbye to invasive drilling and hello to modern, science-backed dental solutions that keep your teeth healthy and alive. Experience the future of dentistry with preservation, not amputation. Learn more today!'
  }
  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{ display: 'flex', flexDirection: 'column', my: 16}}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="description" content={SEO.description} />
        <meta property="og:title" content={SEO.title} />
        <meta property="og:description" content={SEO.description} />
        <meta property="og:url" content='https://www.innovativedental.org/about-us' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={SEO.title} />
        <meta name="twitter:description" content={SEO.description} />
      </Helmet>

      <div className={'page-title'}>
        Biomimetic Dentistry
      </div>
      <div className={classes.contents}>
        <h1>Biomimetic Dentistry</h1>
        <p>So what’s ‘biomimetic’?  Think Luke Skywalker in The Empire Strikes Back, Episode 5. Luke had his epic battle with Darth Vader and lost his hand.  He’s in the med lab where robots are grafting  synthetic nerves, muscle and bone to rebuild his hand so that his fingers flex like normal, he can grip objects and last but not least, he can feel pain (the robot sticks him with a needle).  It’s not a cellular fix but it’s the next best thing. And, they didn’t have to cut any of his arm off to make a wooden one work…</p>
        <h2>ADHESIVES TO REBUILD THE TOOTH</h2>
        <p>In dentistry, something similar happens to the tooth like in the Luke Skywalker story.  First, we leave everything that is healthy alone! Adhesive materials are used to rebuild what’s missing, layer by layer, back to original strength. Your tooth feels ‘whole’ again. It bends and flexes like normal. The adhesives are so strong that if used to full potential, you’re tooth will break before the man made stuff.  However, with Biomimetic Dentistry we build in a fail safe zone that will break if you happen to bite on a rock in your stone ground bread(!).</p>
        <h2>NO AMPUTATION</h2>
        <p>At Innovative Dental Solutions we don’t have to cut the healthy tooth off to make the “fix” work. The ‘fix’ in traditional dentistry is a ‘cap’ or crown. It was fine 100 years ago (think Civil War) but not now. That’s why we call them ‘civil war’ crowns. Cut, cut, cut. The good surgeon was the fast surgeon so you didn’t die of shock. Grind, grind, grind. Cut all the healthy enamel off.  Beat up the nerve.  Now you have a 3% chance per year that the nerve is going to die. Then you need root canal treatment; and a new civil war crown. And now there’s less tooth to support the crown. Then you’re tooth breaks and get’s extracted. Sound familiar? Dentistry has an answer: implants! Implants ARE great, but what’s even greater is keeping your tooth healthy and alive with minimal treatment using adhesives. Why penalize your tooth with century-old treatment? Modern materials allow for a paradigm shift in treatment based on ‘preservation’ and not amputation.</p>
        <h2>PRESERVATION OF HEALTHY TOOTH</h2>
        <p>We know that you only get one set of teeth. 32 of them to be exact. You cannot magically re-grow new teeth. That’s why at Innovative Dental Solutions our only solution is to preserve the healthy teeth that you have, and treat the problem with minimally invasive techniques. Our promise is to always save as much healthy tooth as possible when you visit our office.</p>
      </div>
    </Container>
  )
};

export default BiomimeticDentistryPage;
