import React from 'react';
import Container from "@mui/material/Container";
import classes from './PrivacyPolicyPage.module.scss';

const PrivacyPolicyPage = () => {
  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{ display: 'flex', flexDirection: 'column', my: 16}}
    >
      <div className={'page-title'}>
        Privacy Policy
      </div>
      <div className={classes.contents}>
        <h2>Effective Date: November 25 2024</h2>
        <p>[Innovative Dental Solutions/Haneul Shin DDS PC] (“we”, “us”, “our”) respects your privacy and is committed to protecting your information. This Privacy Policy explains how we collect, use, and share information when you opt in to receive SMS messages from us.</p>

        <h2>Information We Collect</h2>
        <p>When you opt in to receive SMS messages, we collect:
          <li>Your phone number</li>
          <li>Consent to send SMS messages</li>
        </p>

        <h2>How We Use Your Information</h2>
        <p>
          We use your information to:
          <li>Send you the SMS messages you’ve opted in to receive</li>
          <li>Provide updates, promotions, or other relevant content based on your preferences</li>
        </p>

        <h2>Sharing Your Information</h2>
        <p>We do not share your phone number or SMS opt-in information with third parties for marketing purposes.</p>

        <h2>Your Rights</h2>
        <p>You can opt out receiving SMS messages at any time by replying with “STOP” to any message we send you.</p>

        <h2>Data Security</h2>
        <p>We implement reasonable measures to protect your personal information from unauthorized access or disclosure.</p>
      </div>
    </Container>
  )
};

export default PrivacyPolicyPage;
