import React, {useEffect} from 'react';
import {styled} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Divider, Drawer, Tab, Tabs, Toolbar, useTheme} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import classes from './SiteAppBar.module.scss';
import {useLocation, useNavigate} from "react-router-dom";
import DesktopTopBarContents from "./DesktopTopBarContents";
import {LogoImage, MainMenuInfo} from "../../contents/SiteInformations";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  backdropFilter: 'blur(24px)',
  padding: '8px 12px',
}));

const SiteAppBar = (props: any)=> {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [currentMenu, setCurrentMenu] = React.useState('hidden');

  useEffect(() => {
    const findMenu = Object.keys(MainMenuInfo).find((key: string) => {
      return location.pathname.startsWith(MainMenuInfo[key].url);
    });
    if (findMenu) {
      setCurrentMenu(findMenu);
    } else {
      setCurrentMenu('hidden')
    }
  }, [location]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const onClickHandleMenuItem = (k: string) => {
    navigate(MainMenuInfo[k].url);
    setTimeout(() => {
      setOpen(false);
    })
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: 'white',
        backgroundImage: 'none',
      }}
    >
      <div className={classes.desktopTopBarWarpper}>
        <Container maxWidth="lg"
                   sx={{
                     'color': theme.vars.palette.text.secondary,
                     display: { xs: 'none', md: 'fixed' },
                     height: '54px'
                   }}>
          <DesktopTopBarContents />
        </Container>
      </div>
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          {/*Desktop Version Logo & AppBar*/}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center', px: 0 }}>
            <img className={classes.appBarLogo} src={LogoImage} alt={''} onClick={() => navigate('/')} />
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Tabs value={currentMenu}
                  arai-label={"main menu"}
                  indicatorColor={"primary"}
                  textColor={"primary"}>
              <Tab key={"hidden"} value="hidden" label="" style={{display: 'none'}} />
              {
                Object.keys(MainMenuInfo).map((key: string) => (
                  <Tab key={key} value={key}
                       label={MainMenuInfo[key].title}
                       onClick={() => navigate(MainMenuInfo[key].url)}
                  >
                  </Tab>
                ))
              }
            </Tabs>
          </Box>

          {/*Mobile version AppBar*/}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1, justifyContent: 'center', alignItems: 'center' }} style={{width: '100%'}}>
            <img className={classes.appBarLogoMobile}
                 src={LogoImage} alt={''}
                 onClick={() => navigate('/')} />
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)} style={{position: 'absolute', right: '0'}}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="top"
              open={open}
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: {
                  top: 'var(--template-frame-height, 0px)',
                },
              }}
            >
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                {
                  Object.keys(MainMenuInfo).map((key: string) => (
                    <MenuItem key={key} onClick={() => (onClickHandleMenuItem(key))}>
                      {MainMenuInfo[key].title}
                    </MenuItem>
                  ))
                }
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
      <Divider />
    </AppBar>
  );
};

export default SiteAppBar;
