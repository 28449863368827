import React from 'react';
import Container from "@mui/material/Container";
import classes from '../ServicesPage.module.scss';
import MainImage from '../../../assets/services/hygiene.jpg';
import {Helmet} from "react-helmet-async";
const HomeHygienePage = () => {
  const SEO = {
    title: 'Innovative Dental Solutions - Home Hygiene',
    description: 'Upgrade your oral hygiene routine! Ditch harsh brushing and ‘wiggle’ your toothbrush for a gentler, more effective clean. Floss in front of a mirror to target hidden plaque, then flush out bacteria with a Waterpik for healthier gums. Finish with baking soda to neutralize acids and protect your teeth. Smarter techniques for a cleaner, healthier smile—start today!'
  }
  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{display: 'flex', flexDirection: 'column', my: 16}}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="description" content={SEO.description} />
        <meta property="og:title" content={SEO.title} />
        <meta property="og:description" content={SEO.description} />
        <meta property="og:url" content='https://www.innovativedental.org/about-us' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={SEO.title} />
        <meta name="twitter:description" content={SEO.description} />
      </Helmet>
      <div className={'page-title'}>
        Home Hygiene
      </div>
      <div className={classes.contents}>
        <img className={classes.contentsImage} src={MainImage} alt={''}/>
        <h1>Dr.'s Home Hygiene Instructions:</h1>
        <p>
          <ol>
            <li>Wiggle, don’t brush.</li>
            <li>Floss in front of the bathroom mirror, watch</li>
            <li>Use a ‘WaterPik’ after flossing</li>
            <li>Work the baking soda into your gums.</li>
          </ol>
        </p>
        <h1>Wiggle, don’t brush.</h1>
        <p>Everyone learned to brush at age ‘4’ when mom put a ‘brush’ in their hand and commanded them to brush.  The only motor control you had was gross control so you grabbed the brush with your fist and started ‘brushing’ by moving your hand back and forth over your/across your teeth.  That’s a great movement for a paint roller but destructive to your gums and doesn’t really clean efficiently at the gum line.  Instead, rename your brush to the “wiggle”.  Hold the ‘wiggle’ with your fingertips and wiggle the bristle into the nooks and crannies at the gum line.  Holding the wiggle with fingertips only makes it impossible to ‘wire brush’ the gums and cause recession.  Secondly, it’s very effective at cleaning the plaque off.</p>
        <h1>Floss in front of the bathroom mirror, and watch.</h1>
        <p>The whole deal with the floss is to clean where the ‘wiggle’ can’t get to which is between the teeth and under the gum.  Unless you’re watching where you’re going, you won’t get the floss under the gums to loosen up the lumps of bugs that are attached to the tooth.  These colonies produce acids that make a hole in your tooth (decay) or keep your gums inflamed which ultimately means losing bone around your teeth.</p>
        <h1>Use a ‘Waterpik’ after flossing.</h1>
        <p>Flossing loosens all the bacteria up but doesn’t really get much of them out from under the gums.  Using a dental ‘fire hose’ flushes them out and your gums can get a ‘5.0’ A on a ‘4.0’ grading scale.  Your mouth will feel much cleaner as well. </p>
        <h1>Buy a Waterpik that has two features:</h1>
        <p style={{paddingLeft: '20px'}}>
          <li>A water reservoir</li>
          <li>‘Control the flow’ knob.</li>
          <li>Or, just buy the “Travel Model” Waterpik; it has both and is easy to use.</li>
        </p>
        <p>For the new Waterpik user, fill with WARM water. “Tap temperature” is 30-40 degrees colder than you are.  Shooting cold water under the gums HURTS!</p>
        <h1>Work the baking soda into your gums.</h1>
        <p>Load up your damp but clean “wiggle” (formerly called a “brush”) with baking soda and work it into the gums and between the teeth with the same ‘wiggling’ motion that you used to ‘brush’ with.  By the time you’ve done this throughout your mouth, it’s time to rinse.  The baking soda neutralizes the acids found in your mouth, making it less hospitable for the bacteria. </p>


      </div>
    </Container>
  )
};

export default HomeHygienePage;
