import React from 'react';
import './App.scss';
import AppTheme from "./theme/AppTheme";
import {CssBaseline} from "@mui/material";
import SiteAppBar from "./components/site/SiteAppBar";
import Footer from "./components/site/Footer";
import {useRoutes} from "react-router-dom";
import routes from "./routes/routes";
import {Helmet} from "react-helmet-async";
import {ZocDocScript} from "./components/site/ZocDoc";
import {DefaultSEOInfo} from "./contents/SiteInformations";

function App(props: { disableCustomTheme?: boolean }) {
  const routing = useRoutes(routes());

  return (
    <AppTheme {...props}>
      <Helmet>
        <script dangerouslySetInnerHTML={{__html: ZocDocScript}}></script>
      </Helmet>
      <Helmet>
        <title>{DefaultSEOInfo.title}</title>
        <meta name="description" content={DefaultSEOInfo.description} />
        <meta property="og:title" content={DefaultSEOInfo.title} />
        <meta property="og:description" content={DefaultSEOInfo.description} />
        <meta property="og:url" content='https://www.innovativedental.org' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={DefaultSEOInfo.title} />
        <meta name="twitter:description" content={DefaultSEOInfo.description} />
      </Helmet>
      <CssBaseline enableColorScheme />
      <SiteAppBar></SiteAppBar>
      {
        routing
      }
      <Footer />
    </AppTheme>
  );
}

export default App;
