import React from 'react';
import classes from './DesktopTopBarContents.module.scss';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {Location, PhoneNumber} from "../../contents/SiteInformations";
import {ZocDocButton} from "./ZocDoc";

const DesktopTopBarContents = () => {
  const onClickPhone = () => {
    window.open(PhoneNumber.href);
  }
  const onClickLocation = () => {
    window.open(Location.href);
  }

  return (
    <div className={classes.desktopTopBar}>
      <div className={classes.contactInfo} onClick={onClickPhone} >
        <LocalPhoneIcon className={classes.icon} fontSize={'small'} />
        <span className={classes.info}>{PhoneNumber.display}</span>
      </div>

      <div className={classes.contactInfo} onClick={onClickLocation}>
        <LocationOnIcon className={classes.icon} fontSize={'small'}/>
        <span className={classes.info}>{Location.display}</span>
      </div>

      <div className={classes.spacer}></div>
      <div dangerouslySetInnerHTML={{__html: ZocDocButton}}></div>
    </div>
  )
}

export default DesktopTopBarContents;
