import React from 'react';
import {Container, Divider, Grid2} from "@mui/material";
import classes from './AboutUsPage.module.scss';
import DrImage from '../../assets/member/dr.jpg';
import {Helmet} from "react-helmet-async";

import M1 from '../../assets/member/m_jen.jpg';
import M3 from '../../assets/member/m_edna.jpg';
import ALL_MEMBER from '../../assets/member/all_member.jpg';

const AboutUsPage = () => {
  const SEO = {
    title: 'Innovative Dental Solutions - About Us',
    description: 'Eastbay[Lafayette/Orinda/Moraga] Dental Clinic. Biomimetic Dentistry. Dental Implants.'
  };
  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{ display: 'flex', flexDirection: 'column', my: 16}}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="description" content={SEO.description} />
        <meta property="og:title" content={SEO.title} />
        <meta property="og:description" content={SEO.description} />
        <meta property="og:url" content='https://www.innovativedental.org/about-us' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={SEO.title} />
        <meta name="twitter:description" content={SEO.description} />
      </Helmet>

      <div className={'page-title'}>
        About Us
      </div>
      <div className={classes.contents}>
        <h1>Welcome to Innovative Dental Solutions.</h1>
        <h1>DR. HANEUL SHIN</h1>
        <Grid2 container className={classes.bioWrapper} direction={{xs: 'column', md: 'row'}}>
          <Grid2 size={{xs: 12, md: 6}} style={{padding: '5px'}}>
            <img className={classes.bioImage} src={DrImage} alt={''}/>
          </Grid2>
          <Grid2 size={{xs: 12, md: 6}} style={{padding: '5px'}}>
            <h1>DOCTOR BIO</h1>
            <p>
              Dr. Shin is a dual board-certified dentist and offers a range of advanced procedures that can help you maintain a beautiful and healthy smile for a lifetime. Dr. Shin earned her Doctor of Dental Surgery degree from Jeonbuk National University School of Dentistry in Korea and completed a specialty program in Advanced Clinical Dentistry in Korea. Dr. Shin received her second Doctor of Dental Surgery degree from the University of the Pacific, Arthur A. Dugoni School of Dentistry.
            </p>
            <p>
              Known for a gentle and compassionate approach, Dr. Shin strives to create a comfortable environment where patients feel at ease. Attending continuous programs at Plastic surgery and Reconstructive Surgery Clinic at Stanford Medicine, Boston University of Dental Medicine, and the University of British Columbia in Vancouver, Dr. Shin is committed to staying abreast of the latest advancements in dental technology and techniques to ensure the highest standard of care.
            </p>
            <p>
              Outside of the office, Dr. Shin is actively involved in community outreach programs and believes in educating patients about the importance of oral hygiene for overall health. Dr. Shin participated in a dental mobile van at the San Mateo Medical Center, La Clinica in Oakland, cleft lips and palates surgery at Hue University of Medicine in Vietnam. In her free time, Dr. Shin enjoys spending time with her husband and daughter, and finding fun things to do in the Bay Area.
            </p>
            <p>
              Dr. Shin welcomes new patients and looks forward to providing exceptional dental care to the community for years to come.
            </p>
          </Grid2>
        </Grid2>
      </div>
      <Divider />
      <div className={classes.contents}>
        <h1>Our Staffs</h1>
        <Grid2 container className={classes.bioWrapper} direction={{xs: 'column', md: 'row'}}>
          <Grid2 size={{xs: 12, md: 6}} style={{padding: '5px'}}>
            <div className={classes.staffTitleWrapper}>
              <h1>● Office Manager: Jeniffer</h1>
            </div>
            <img className={classes.bioImage} src={M1} alt={''}/>
          </Grid2>
          {/*<Grid2 size={{xs: 12, md: 4}} style={{padding: '5px'}}>*/}
          {/*  <div className={classes.staffTitleWrapper}>*/}
          {/*    <h1>● Dental Receptionist: Michele</h1>*/}
          {/*  </div>*/}
          {/*  <img className={classes.bioImage} src={M2} alt={''}/>*/}
          {/*</Grid2>*/}
          <Grid2 size={{xs: 12, md: 6}} style={{padding: '5px'}}>
            <div className={classes.staffTitleWrapper}>
              <h1>● Dental Hygienist: Edna</h1>
            </div>
            <img className={classes.bioImage} src={M3} alt={''}/>
          </Grid2>
          {/*<Grid2 size={{xs: 12, md: 6}} style={{padding: '5px'}}>*/}
          {/*  <h1>● Dental Assistant: Monica</h1>*/}
          {/*  <img className={classes.bioImage} src={M4} alt={''}/>*/}
          {/*</Grid2>*/}
          {/*<Grid2 size={{xs: 12, md: 12}} style={{padding: '5px'}}>*/}
          {/*  <img className={classes.bioImage} src={ALL_MEMBER} alt={''}/>*/}
          {/*</Grid2>*/}
        </Grid2>
      </div>
    </Container>
  );
}

export default AboutUsPage;
