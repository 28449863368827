import React from 'react';
import Container from "@mui/material/Container";
import classes from '../ServicesPage.module.scss';
import OzoneMainImage from '../../../assets/services/ozone.jpg';
import {Helmet} from "react-helmet-async";
const OzonTherapyPage = () => {
  const SEO = {
    title: 'Innovative Dental Solutions - Ozone Therapy',
    description: 'Eliminate oral infections with ozone therapy! Ozone kills bacteria, viruses, and fungi—preventing cavities, treating gum disease, and stopping cold sores in their tracks. Safe and effective, ozone sterilizes teeth, enhances bonding, and helps avoid root canals by preserving healthy tooth structure. Say goodbye to bacteria and hello to stronger, healthier teeth! Discover the power of ozone therapy today.'
  };
  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{display: 'flex', flexDirection: 'column', my: 16}}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="description" content={SEO.description} />
        <meta property="og:title" content={SEO.title} />
        <meta property="og:description" content={SEO.description} />
        <meta property="og:url" content='https://www.innovativedental.org/about-us' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={SEO.title} />
        <meta name="twitter:description" content={SEO.description} />
      </Helmet>
      <div className={'page-title'}>
        Ozone Therapy
      </div>
      <div className={classes.contents}>
        <img className={classes.contentsImage} src={OzoneMainImage} alt={''}/>
        <h1>Why Ozone?</h1>
        <p>Simply because ozone kills the source(s) of all oral infection.  When treating a cavity we are removing mushy decay, but the ozone will kill the remaining bacteria in an otherwise healthy tooth structure that can lead to a new cavity. Hence, “recurrent” decay. Ozone can also kill the viruses that causes canker sores and fever blisters, diminishing pain immediately. The healing starts NOW. </p>
        <h2>What is Ozone?</h2>
        <p>Ozone is three atoms of oxygen stuck together instead of the normal two that we breathe.</p>
        <h2>What does it do?</h2>
        <p>Ozone kills all bacteria, yeasts, fungi, molds and parasites, our enemies. It is commonly used for disinfection and sterilization in a range of areas from water treatment facilities to operating rooms and making distilled water. Just check the labels at your local grocery store.</p>
        <h2>How do you make it?</h2>
        <p>Medical grade oxygen flows through an electric plasma field, “controlled lightning”.  The energy created by the device breaks the oxygen molecules apart, allowing them to re-bond as ozone (O3). Our ozone generator meets Canadian, US, North American and European medical device regulatory standards.</p>
        <h2>Is it safe?</h2>
        <p>As with any drug, inappropriate use can be harmful.  Dr. Ruefenacht is a founding member and Fellow of the American Academy of Ozone Therapy and follows the AAOT and  Madrid Protocols (ICOS).</p>
        <h2>What do you do with it?</h2>
        <p>
          <li>Desensitize teeth to hot and cold</li>
          <li>Kill bacteria inside healthy tooth structure to allow for strong adhesive bonds</li>
          <li>Kill the viruses that cause canker and cold sores (and the pain is gone too!)</li>
          <li>Kill the bacteria in all of the microscopic places during root canal treatment</li>
          <li>Treat gum infections such as gingivitis and periodontitis</li>
        </p>
        <h2>Good Bye Bacteria, Hello Happy Nerve.</h2>
        <p>The healthy nerve has the potential to repair decay-damaged tooth structure if the bacteria are killed and the area sealed against further bacteria.  Bye Bye root canal!  I use ozone therapies to kill the bacteria and advanced bonding techniques to seal the tooth surface against new bacterial infection. Bacteria left in the healthy tooth causes new decay and an abscessed tooth.</p>
      </div>
    </Container>
  )
};

export default OzonTherapyPage;
