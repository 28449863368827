import React from 'react';
import {Container} from "@mui/material";
import classes from './Footer.module.scss';
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

import {Email, Location, PhoneNumber} from "../../contents/SiteInformations";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {ZocDocButton} from "./ZocDoc";


const Footer = (props: any) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className={classes.footer}>
        <Container maxWidth="lg">
          <div className={classes.footerContents}>
            <div className={classes.footerTitle}>
              Appointment
            </div>
            <div className={classes.footerDescription}>
              Come explore your alternative options before you look your next 'civil war' dentist appointment.
            </div>

            <div className={classes.contactInfo}>
              <div style={{display: 'flex', gap: '10px', cursor: 'pointer'}} onClick={() => window.open(PhoneNumber.href)}>
                <LocalPhoneIcon className={classes.contactIcon} fontSize={'small'} />
                <div className={classes.contactText}>
                  {PhoneNumber.display}
                </div>
              </div>
            </div>
            <div className={classes.contactInfo} >
              <div style={{display: 'flex', gap: '10px', cursor: 'pointer'}} onClick={() => window.open(Location.href)}>
                <LocationOnIcon className={classes.contactIcon} fontSize={'small'}/>
                <div className={classes.contactText}>
                  {Location.display}
                </div>
              </div>
            </div>
            <div className={classes.contactInfo}>
              <div style={{display: 'flex', gap: '10px', cursor: 'pointer'}} onClick={() => window.open(Email.href)}>
                <EmailIcon className={classes.contactIcon} fontSize={'small'}/>
                <div className={classes.contactText}>
                  {Email.display}
                </div>
              </div>
            </div>

            <div className={classes.tcLinks}>
              <div className={classes.tcLink} onClick={()=> (navigate('/terms'))}>Terms and Conditions</div>
              <span>/</span>
              <div className={classes.tcLink} onClick={() => (navigate('/privacy-policy'))}>Privacy Policy</div>
            </div>

            <Box sx={{display: { xs:'flex', md: 'none'}, margin: '15px 0'}} className={classes.zocdocButtonWrapper}>
              <div dangerouslySetInnerHTML={{__html: ZocDocButton}}></div>
            </Box>
            <div className={classes.copyRight}>
              © 2025 Innovative Dental Solutions & Haneul Shin DDS PC All Rights Reserved
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Footer;
