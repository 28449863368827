import React from 'react';
import Container from "@mui/material/Container";
import classes from '../ServicesPage.module.scss';
import ImplantMainImage from '../../../assets/services/implant.jpg';
import {Helmet} from "react-helmet-async";
const DentalImplantPage = () => {
  const SEO = {
    title: 'Innovative Dental Solutions - Dental Implants',
    description: 'Restore your smile with dental implants! Since 2015, Dr. Shin has been expertly placing implants to replace missing teeth with natural-looking, fully functional solutions. Implants prevent jaw issues, stop unwanted tooth movement, and eliminate the need for unnecessary crowns. Our advanced procedure ensures a secure, long-lasting fit through a precise healing and fusion process. Get a permanent, confident smile—schedule your consultation today!'
  }
  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{display: 'flex', flexDirection: 'column', my: 16}}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="description" content={SEO.description} />
        <meta property="og:title" content={SEO.title} />
        <meta property="og:description" content={SEO.description} />
        <meta property="og:url" content='https://www.innovativedental.org/about-us' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={SEO.title} />
        <meta name="twitter:description" content={SEO.description} />
      </Helmet>

      <div className={'page-title'}>
        Dental Implants
      </div>
      <div className={classes.contents}>
        <img className={classes.contentsImage} src={ImplantMainImage} alt={''}/>
        <p>Dr. Shin has been surgically placing implants since 2015.</p>
        <h1>What are they?</h1>
        <p>A dental implant is an artificial root that is placed in the bone.  After a period of healing, a crown is attached to your implant restoring original aesthetics and function.</p>
        <h1>Why should I have one?</h1>
        <p>
          Missing teeth are not only aesthetically displeasing but are also functionally compromising:
          <li>
            Posterior (back) teeth establish the bite and protect the jaw joints, much like the foundation to your house. Losing one or more posterior teeth can lead to TMJ issues and premature wear of remaining teeth.
          </li>
          <li>
            A missing tooth can cause unwanted movement of adjacent teeth.
          </li>
          <li>
            Implants allow us to avoid the needless capping of adjacent teeth for bridge placement.
          </li>
        </p>
        <h1>What does the dental implant procedure involve?</h1>
        <p>During your dental implant procedure, your dentist administers an anesthetic or sedation before making an incision in your gums and drilling small holes into your jawbone.</p>
        <p>Next, they put the titanium implants in the holes and closes your gums with stitches to allow your new implants to fuse with your jaw over time.</p>
        <p>After your dentist places your implants, the next step is the fusion process (osseointegration). This typically takes around 3-6months for your jawbone and implants to fuse properly.
          Following the completion of the healing process, you go back to the office and your dentist attaches a supporting element called an abutment on the top of each implant.
        </p>
        <p>
          Lastly, your prosthesis is secured to the abutments.
        </p>
      </div>
    </Container>
  )
};

export default DentalImplantPage;
