import React from 'react';
import Container from "@mui/material/Container";
import classes from '../ServicesPage.module.scss';
import MainImage from '../../../assets/services/root-canal.jpg';
import {Helmet} from "react-helmet-async";
const BiologicalRootCanalPage = () => {
  const SEO = {
    title : 'Innovative Dental Solutions - Biological Root Canal Therapy',
    description: 'Biological Root Canal Treatment goes beyond traditional methods by eliminating bacteria at every level. Using ozone therapy, laser-activated irrigation, and antibacterial silver salts, we effectively remove biofilm and sterilize the tooth to prevent reinfection. Unlike conventional root canals, our approach prioritizes long-term tooth health while minimizing risks. Safe, effective, and backed by modern science—choose a smarter alternative to extraction. Learn more about our holistic and biomimetic dental solutions today!'
  }
  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{display: 'flex', flexDirection: 'column', my: 16}}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="description" content={SEO.description} />
        <meta property="og:title" content={SEO.title} />
        <meta property="og:description" content={SEO.description} />
        <meta property="og:url" content='https://www.innovativedental.org/about-us' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={SEO.title} />
        <meta name="twitter:description" content={SEO.description} />
      </Helmet>
      <div className={'page-title'}>
        Biological Root Canal Therapy
      </div>
      <div className={classes.contents}>
        <img className={classes.contentsImage} src={MainImage} alt={''}/>
        <h1>What is Biological Root Canal Treatment?</h1>
        <p style={{paddingLeft: '20px'}}>
          <li>Removes the bacteria-laden biofilm lining “the canal</li>
          <li>Kills bacteria in the hard tooth as well as the canal</li>
          <li>Uses bacteria-killing filling material to help ensure sterility</li>
        </p>
        <p>You’ve maybe heard that all root canals are bad and should be avoided. Instead, the tooth should be extracted because toxin-creating bacteria are left inside the tooth. Holistic medicine has preached this for nearly 100 years. Modern science suggests that it is true.</p>
        <p>Traditional root canal treatment is basically the dental version of ‘roto-rootering’ a plugged-up drain: rotary instruments are used to clean out the nerve inside the root’s canal.  But this leaves a smear layer composed of bacteria, tooth shavings, and nerve remnants. For a lot of dull engineering reasons, this biofilm is nearly impossible to entirely remove. This gets left behind after the canals are sealed up; hopefully tighter than Pharoah’s tomb.  But the bugs are still there to grow. And eventually, in some cases, to cause new infections.</p>
        <h1>So what’s different with biological root canal treatment? Lots!!!</h1>
        <p>We start off with traditional root canal techniques to remove the bulk of the nerve and then add biological components to ensure the bullet points above. This includes the use of ozone gas (kills all pathogens on contact); laser energy activation of our irrigants which scanning electron microscope photography shows removes the biofilm smear layer; and painting the canal with silver salts which remain to kill any new bugs.</p>
        <h1>Does this cost extra?</h1>
        <p>No, it’s part of my standard treatment technique</p>
        <h1>Does this guarantee that the infection is completely gone?</h1>
        <p>No.  Medicine can’t ever guarantee a result. But it’s the best for now and light years better than standard techniques.</p>
        <h1>Why does holistic medicine/dentistry still claim root canals are bad?</h1>
        <p>Unfortunately, they haven’t kept up with advances in science. Regardless of professions, we must always be looking for advances in understanding even if it means changing our beliefs.</p>
        <h1>Can every tooth be saved?</h1>
        <p>No.  Every patient is different, and every case is different. But for the otherwise healthy patient, biological root canal treatment is a better choice than extraction.</p>
      </div>
    </Container>
  )
};

export default BiologicalRootCanalPage;
