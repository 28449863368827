import React from 'react';
import classes from './ImageRotator.module.scss';
import {MainImageRotatorList} from "../../contents/SiteInformations";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const ImageRotator = () => {
  return (
    <div className={classes.carouselWrapper}>
      <Carousel additionalTransfrom={0}
                arrows={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                centerMode={false}
                className={classes.carouselContainer}
                dotListClass=""
                draggable={false}
                focusOnSelect={false}
                infinite={true}
                keyBoardControl={true}
                minimumTouchDrag={80}
                pauseOnHover={true}
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay={true}
                showDots={true}
                sliderClass=""
                slidesToSlide={1}
                swipeable={true}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 1
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 1
                  }
                }}
      >
        {
          MainImageRotatorList.map((item, i) => (
            <img className={classes.imageItem}
                 alt={''}
                 key={`image-carousel-${i}`}
                 src={item.image} />
          ))
        }
      </Carousel>
    </div>
  );
};

export default ImageRotator;

